import Swiper, { Navigation, Autoplay, Pagination } from "swiper";

// init Swiper:
const swiper = new Swiper(".slide--testimonials", {
	loop: true,
	slidesPerView: 1,
	spaceBetween: 30,
	navigation: {
		nextEl: ".arrow--next",
		prevEl: ".arrow--prev",
	},
	/* 	autoplay: {
		delay: 3500,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	}, */
	pagination: {
		el: ".swiper-pagination",
		type: "bullets",
		clickable: true,
	},
	modules: [Autoplay, Navigation, Pagination],
	breakpoints: {
		1024: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
	},
});
